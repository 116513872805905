<template>
  <footer class="footer bg-secondary items-center justify-center p-4">
    <aside class="grid-flow-col items-center">
      <div class="flex flex-col lg:flex-row items-center gap-4">
        <p class="text-center text-neutral">Copyright © {{ getCurrentYear() }} | MônBKK</p>
        <span class="flex flex-row items-center gap-2">
          <a :href="fbLink" target="_blank" rel="noopener noreferrer"
            ><IconFb class="hover:text-white"
          /></a>
          <a :href="instaLink" target="_blank" rel="noopener noreferrer"
            ><IconInsta class="hover:text-white"
          /></a>
        </span>
      </div>
    </aside>
  </footer>
</template>

<script setup>
import { getCurrentYear } from '@/utils/generators'

import IconFb from './icons/IconFb.vue'
import IconInsta from './icons/IconInsta.vue'

const fbLink = 'https://www.facebook.com/profile.php?id=61568842264459'
const instaLink = 'https://www.instagram.com/mon_bangkok/'
</script>
