<template>
  <!-- Hero Section -->
  <section
    class="relative h-screen flex items-center justify-center bg-cover bg-center"
    :style="{ backgroundImage: `url(${BgImage})` }"
  >
    <!-- Overlay -->
    <div class="absolute inset-0 bg-secondary opacity-65"></div>

    <!-- Content -->
    <div
      class="z-10 flex flex-col-reverse lg:flex-row justify-between w-full px-6 md:px-12 items-center max-w-6xl gap-6 lg:gap-0"
    >
      <div class="text-center lg:text-left lg:w-1/2 lg:space-y-8">
        <div class="space-y-3">
          <h1 class="text-xl md:text-2xl font-regular text-primary uppercase">{{ heroText }}</h1>
          <p class="text-lg md:text-xl text-primary font-medium">
            {{ heroSubText.split('.')[0] }}
          </p>
        </div>
        <button
          class="mt-6 px-8 py-2 bg-primary text-white font-semibold text-md rounded-lg shadow-lg hover:bg-primary-focus transition"
          aria-label="reserve-cta"
          @click="scrollToReserve"
        >
          Reserve
        </button>
      </div>
      <div class="lg:w-1/2 flex justify-center">
        <img :src="MonLogo" alt="logo" rel="preload" class="h-32 w-auto md:h-48 lg:h-60" />
      </div>
    </div>
  </section>
  <!-- About Us -->
  <section
    class="flex flex-col-reverse lg:flex-row justify-between items-center w-full px-6 md:px-12 mt-12 max-w-auto mx-auto space-y-2 lg:space-y-0 gap-6 lg:gap-12"
  >
    <div class="lg:w-1/2">
      <img
        :src="AboutImage"
        alt="about"
        rel="preload"
        class="h-auto w-full rounded-lg drop-shadow-md animate-fade-in"
      />
    </div>
    <div class="lg:w-1/2 space-y-3 max-w-prose">
      <h2
        class="text-lg md:text-2xl text-center lg:text-left font-semibold text-primary flex flex-col lg:flex-row items-center gap-2"
      >
        <IconHills />{{ aboutTitle }}
      </h2>
      <p class="text-justify lg:text-left text-white leading-relaxed whitespace-pre-line">
        {{ aboutText }}
      </p>
    </div>
  </section>
  <!-- Chef -->
  <section
    class="flex flex-col justify-between items-center w-full px-6 md:px-12 mt-12 max-w-auto mx-auto"
  >
    <div class="divider lg:divider-start">
      <h2 class="text-lg md:text-2xl font-semibold text-primary flex flex-row items-center gap-2">
        {{ chefTitle }}
        <IconChef />
      </h2>
    </div>
    <div class="flex flex-col-reverse lg:flex-row space-y-8 lg:space-y-0 lg:gap-12">
      <div class="text-justify lg:text-left lg:w-1/2 space-y-3 max-w-prose">
        <p class="text-white leading-relaxed whitespace-pre-line">{{ chefText }}</p>
      </div>
      <div class="lg:w-1/2 overflow-hidden">
        <img
          :src="ChefImage"
          alt="chef"
          rel="preload"
          class="h-auto w-full rounded-lg drop-shadow-md animate-fade-in"
        />
      </div>
    </div>
  </section>
  <!-- Food -->
  <section
    class="flex flex-col justify-between items-center w-full px-6 md:px-12 mt-6 max-w-auto mx-auto"
  >
    <div class="divider lg:divider-start">
      <h2 class="text-lg md:text-2xl font-semibold text-primary flex flex-row items-center gap-2">
        Menu
        <IconFood />
      </h2>
    </div>
    <div class="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:gap-12">
      <div class="text-justify lg:text-left lg:w-1/2 max-w-prose">
        <p class="text-white leading-relaxed whitespace-pre-line">{{ foodTextLeft }}</p>
        <img
          :src="FoodLeftImage"
          alt="food"
          rel="preload"
          class="mt-6 h-auto w-full rounded-lg drop-shadow-lg animate-fade-in"
        />
      </div>
      <div class="text-justify lg:text-left lg:w-1/2 max-w-prose">
        <img
          :src="FoodRightImage"
          alt="food"
          class="h-auto w-full rounded-lg drop-shadow-lg animate-fade-in"
        />
        <p class="text-white leading-relaxed whitespace-pre-line">{{ foodTextRight }}</p>
      </div>
    </div>
  </section>
  <!-- Wine -->
  <section
    class="flex flex-col justify-between items-center w-full px-6 md:px-12 mt-6 max-w-auto mx-auto"
  >
    <div class="divider lg:divider-start">
      <h2 class="text-lg md:text-2xl font-semibold text-primary flex flex-row items-center">
        Wine
        <IconWine />
      </h2>
    </div>
    <div class="flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:gap-12 w-full">
      <div class="text-justify lg:text-left lg:w-1/2 max-w-prose flex flex-col gap-6">
        <p class="text-white leading-relaxed whitespace-pre-line">{{ wineText }}</p>
        <img
          :src="WineArtwork"
          alt="wine-artwork"
          rel="preload"
          class="mt-6 h-auto w-full rounded-lg drop-shadow-lg animate-fade-in"
        />
      </div>
      <div class="lg:w-1/2 max-w-prose">
        <img
          :src="WineArtworkLong"
          alt="wine"
          rel="preload"
          class="h-auto w-full rounded-lg drop-shadow-lg animate-fade-in"
        />
      </div>
    </div>
  </section>
  <!-- Contact Us -->
  <section
    class="flex flex-col justify-between items-center w-full px-6 md:px-12 mt-6 lg:mt-12 max-w-auto mx-auto space-y-4"
    id="reserve"
  >
    <div class="divider">
      <h2 class="text-lg md:text-2xl font-semibold text-primary flex flex-row items-center gap-3">
        <IconContact />Contact Us
      </h2>
    </div>
    <div
      class="flex flex-col lg:flex-row space-y-8 lg:space-y-0 gap-6 lg:gap-12 items-center w-full"
    >
      <!-- Contact Section -->
      <div class="text-justify lg:text-left lg:w-1/2 max-w-full space-y-3">
        <div class="flex flex-col space-y-2">
          <div class="flex flex-row space-x-2 items-center">
            <IconEmail class="text-primary" />
            <a :href="`mailto:${emailAddress}`" class="text-white text-sm hover:underline">{{
              emailAddress
            }}</a>
          </div>
          <div class="flex flex-row space-x-2 items-center">
            <IconPhone class="text-primary" />
            <a
              :href="whatsAppLink"
              target="_blank"
              rel="noopener noreferrer"
              class="text-white text-sm hover:underline"
              >{{ phoneNumber }}</a
            >
          </div>
        </div>
        <div class="py-4">
          <!-- map embed -->
          <div class="aspect-video w-full rounded-lg overflow-hidden shadow-lg">
            <iframe
              :src="mapEmbedSource"
              class="w-full h-full border-0"
              allowfullscreen=""
              referrerpolicy="no-referrer-when-downgrade"
              loading="lazy"
              title="location"
            ></iframe>
          </div>
          <div class="flex flex-row space-x-2 items-center">
            <IconMap class="text-primary" />
            <p class="text-white text-sm whitespace-pre-line">{{ addressText }}</p>
          </div>
        </div>
      </div>
      <!-- Reservation -->
      <div class="lg:w-1/2">
        <!-- Reservation form goes here -->
        <div class="border-2 border-neutral border-dashed">
          <h1
            class="text-primary text-lg md:text-4xl font-medium text-start lg:text-left p-8 md:p-12"
          >
            Reservation will start soon! Please Stay Tuned...
          </h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { useHead } from '@vueuse/head'

import MonLogo from '@/assets/mon_logo.png'

import BgImage from '@/assets/images/bkg_img.jpeg'
import AboutImage from '@/assets/images/about_img.jpeg'
import ChefImage from '@/assets/images/chef_img.png'
import FoodLeftImage from '@/assets/images/food_2.png'
import FoodRightImage from '@/assets/images/food_1.png'
import WineArtwork from '@/assets/images/wine_art.png'
import WineArtworkLong from '@/assets/images/wine_art_2.jpeg'

import IconEmail from '@/components/icons/IconEmail.vue'
import IconPhone from '@/components/icons/IconPhone.vue'
import IconMap from '@/components/icons/IconMap.vue'
import IconWine from '@/components/icons/IconWine.vue'
import IconFood from '@/components/icons/IconFood.vue'
import IconChef from '@/components/icons/IconChef.vue'
import IconHills from '@/components/icons/IconHills.vue'
import IconContact from '@/components/icons/IconContact.vue'

const siteUrl = import.meta.env.VITE_APP_PUBLISHED_URL

const heroText = 'Progressive Indigenous Cuisine'
const heroSubText =
  'Stories served on a plate. Celebrate the flavors and tales of the hilly regions.'

useHead({
  title: `Môn | ${heroText}`,
  meta: [
    { name: 'description', content: heroSubText },
    {
      name: 'keywords',
      content: 'Indigenous cuisine, borderlands, progressive food, chef Arpon Changma'
    },
    { property: 'og:title', content: `Môn | ${heroText}` },
    { property: 'og:description', content: heroSubText },
    { property: 'og:image', content: `${siteUrl}/images/mon_logo.png` },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: siteUrl }
  ]
})

const emailAddress = 'changmaarpon@gmail.com'
const phoneNumber = '+66610205670'
const addressText = `
27/1 Sukhumvit 33 Alley
Khlong Tan Nuea, Watthana
Bangkok 10110
`

const whatsAppLink = ref(`https://wa.me/${phoneNumber}`)

const scrollToReserve = () => {
  const section = document.getElementById('reserve')
  section.scrollIntoView({ behavior: 'smooth' })
}

const aboutTitle = 'Rooted in the Hills, Connected through Flavor'
const aboutText = `
Môn" means “hills” in the Changma/Chakma language. Our story is deeply rooted in the hills, spanning the Chittagong Hill Tracts of Bangladesh, the borders of Nepal to Northeast India, Myanmar, and Chiang Rai. These borderlands are alive with untold stories—thrilling, romantic, and resilient. Flavors resonate across regions, and dishes are exchanged through shared histories of migration. In these mountains, the people share a unique palate, bonded by tradition as deeply as by the landscape.

Môn is more than just a restaurant. We are dedicated to sourcing our ingredients from small-scale, root-level farmers who prioritize survival and sustainability over profit. Our mission goes beyond business: we are here to honor and promote the art, culture, and lives of these borderland communities. Through every dish, we aim to tell their stories and shed light on the challenges they face to thrive.

With every bill, you are contributing 5% to the laughter and resilience of these people. This contribution is not an additional charge; it is already part of your total, supporting the lives we celebrate through food.

We believe in the beauty of imperfection. We believe in the flavor of the hills and the power of Mother Nature. Most importantly, we believe in the spirit of these borderlands, where human-made boundaries cannot confine the soul of the mountains. Through food, we celebrate that spirit with you.
`

const chefTitle = 'Meet the Chef'
const chefText = `
I’m Arpon Changma, a chef shaped by the tales of the hills and the flavors of the earth. A storyteller, explorer, and wine enthusiast, my culinary journey began in Kolkata, India. It was there that I honed my skills and deepened my passion for food and wine, earning diplomas from Le Cordon Bleu Dusit and WSET.

My path has taken me through the kitchens of five-star properties across India and Bangladesh. Yet, my heart has always been drawn back to my roots—the hills, where food and folklore intertwine. In these hills, wild produce scents the air, and celebrations break the rugged silence.

Life here is hard, but the people toast even harder, turning each occasion into a memory. The clang of hill women’s ornaments mingles with the earthy smoke of roasting meats and herbs, whisking you away from life’s struggles, even if just for a moment.

Môn is my invitation to you—a space to feel at home, let go of worries, and immerse in these stories. Here, trust in the chef isn’t just a request; it’s a promise.
`

const foodTextLeft = `
At Môn, we craft progressive Indigenous cuisine, drawing inspiration from the culinary traditions of the hilly border regions of Bangladesh, India, Nepal, Myanmar, and Thailand—where these countries’ palates connect.

Our dishes are primarily wood-fired, employing distinctive techniques such as smoking caviar and searing game meat over paddy straw, enhanced with wild herbs and regional spices that bring a unique depth to each plate.
`

const foodTextRight = `
With limited seating, we serve only what’s available and fresh each day, sourcing directly from local farmers and communities. These trusted suppliers provide us with wild, organic, and exceptional ingredients that capture the peak of each season.
`

const wineText = `
At Môn, we focus on wines grown in elevated, mountainous regions, much like the origins of our ingredients. Our priority is sourcing from exceptional, family-owned boutique wineries that practice sustainability and biodegradability.

You can trust us—our chef is a passionate wine enthusiast who carefully selects only well-balanced wines to pair with his creations. Stay tuned as we reveal our wine list soon.
`

const mapEmbedSource = ref(
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.681998702126!2d100.5678622!3d13.7376927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29efc1212335d%3A0x3b86bc380b9eb188!2s27%2F1%20Sukhumvit%2033%20Alley%2C%20Khwaeng%20Khlong%20Tan%20Nuea%2C%20Watthana%2C%20Krung%20Thep%20Maha%20Nakhon%2010110%2C%20Thailand!5e0!3m2!1sen!2sbd!4v1732353925147!5m2!1sen!2sbd'
)
</script>
