import './assets/app.css'

import { createApp } from 'vue'
import { createHead } from '@vueuse/head'

import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as Sentry from '@sentry/vue'
import Vue3Toastify from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

import App from './App.vue'
import router from './router'

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 0.5,
  // Session Replay
  replaysSessionSampleRate: 0.85,
  replaysOnErrorSampleRate: 1.0,
  // Track components
  trackComponents: true,
  hooks: ['activate', 'create', 'unmount', 'mount', 'update'],
  telemetry: false
})

const head = createHead()
app.use(head)

const pinia = createPinia()
app.use(pinia)

app.use(router)

app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

app.use(Vue3Toastify, {
  theme: 'colored',
  autoClose: 3000
})

app.mount('#app')
