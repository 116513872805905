<template>
  <svg viewBox="0 -0.5 25 25" fill="none" class="h-10 w-10" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.48067 5L7.74707 9.994C7.72482 12.5023 9.69522 14.5544 12.1486 14.578C14.6021 14.5544 16.5724 12.5023 16.5502 9.994L15.8166 5H8.48067Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M12.1487 18.25C11.7344 18.25 11.3987 18.5858 11.3987 19C11.3987 19.4142 11.7344 19.75 12.1487 19.75V18.25ZM15.5721 19.75C15.9863 19.75 16.3221 19.4142 16.3221 19C16.3221 18.5858 15.9863 18.25 15.5721 18.25V19.75ZM11.3987 19C11.3987 19.4142 11.7344 19.75 12.1487 19.75C12.5629 19.75 12.8987 19.4142 12.8987 19H11.3987ZM12.8987 14.579C12.8987 14.1648 12.5629 13.829 12.1487 13.829C11.7344 13.829 11.3987 14.1648 11.3987 14.579H12.8987ZM12.1487 19.75C12.5629 19.75 12.8987 19.4142 12.8987 19C12.8987 18.5858 12.5629 18.25 12.1487 18.25V19.75ZM8.72522 18.25C8.31101 18.25 7.97522 18.5858 7.97522 19C7.97522 19.4142 8.31101 19.75 8.72522 19.75V18.25ZM12.1487 19.75H15.5721V18.25H12.1487V19.75ZM12.8987 19V14.579H11.3987V19H12.8987ZM12.1487 18.25H8.72522V19.75H12.1487V18.25Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
