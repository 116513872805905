<template>
  <svg
    fill="currentColor"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    class="h-5 w-5"
  >
    <g>
      <path
        d="M31.261 24.113c-0.489-0.344-5.846-3.905-6.502-4.306-0.286-0.175-0.635-0.261-1.015-0.261-0.489 0-1.032 0.142-1.561 0.421-0.515 0.271-2.077 1.16-2.841 1.596-0.673-0.473-2.254-1.683-4.745-4.177-2.476-2.474-3.697-4.068-4.173-4.745 0.435-0.764 1.323-2.325 1.593-2.839 0.511-0.969 0.57-1.937 0.155-2.589-0.389-0.615-3.937-5.994-4.303-6.5-0.345-0.477-0.963-0.736-1.634-0.736-0.529 0-1.091 0.16-1.578 0.499-0.047 0.033-4.753 3.446-4.691 5.386 0.173 5.451 5.471 11.857 9.883 16.271s10.819 9.713 16.292 9.887h0.045c1.916 0 5.284-4.646 5.316-4.692 0.884-1.262 0.562-2.653-0.243-3.217zM29.879 26.165c-1.268 1.757-3.070 3.592-3.774 3.855-3.751-0.176-9.014-3.473-14.84-9.299s-9.121-11.085-9.295-14.823c0.253-0.707 2.088-2.519 3.829-3.779 0.129-0.089 0.288-0.141 0.436-0.141 0.033 0 0.061 0.003 0.082 0.007 0.563 0.817 3.582 5.389 4.13 6.243 0.002 0.090-0.028 0.318-0.2 0.644-0.182 0.348-0.751 1.361-1.559 2.777l-0.629 1.103 0.729 1.039c0.544 0.772 1.838 2.452 4.395 5.009 2.567 2.569 4.241 3.859 5.011 4.399l1.039 0.73 1.102-0.63c1.069-0.611 2.36-1.342 2.779-1.563 0.337-0.177 0.567-0.192 0.63-0.192 0.010 0 0.019 0 0.026 0.001 0.765 0.48 5.436 3.581 6.26 4.146 0.014 0.080 0.001 0.254-0.151 0.471zM21.372 11.946l8.601-8.549-0.027 4.845c-0.009 0.405 0.311 0.756 0.716 0.747l0.513-0.001c0.405-0.010 0.739-0.25 0.748-0.654l0.020-7.219c0-0.007-0.027-0.012-0.027-0.019l0.040-0.366c0.004-0.203-0.043-0.384-0.175-0.513-0.129-0.131-0.311-0.21-0.512-0.204l-0.366 0.009c-0.007 0-0.012 0.003-0.020 0.004l-7.171-0.032c-0.404 0.009-0.738 0.343-0.747 0.748l-0.001 0.513c0.061 0.476 0.436 0.755 0.84 0.746l4.727 0.013-8.572 8.52c-0.39 0.39-0.39 1.024 0 1.415s1.023 0.39 1.414 0z"
      ></path>
    </g>
  </svg>
</template>
