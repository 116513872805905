<template>
  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="none" class="h-7 w-7">
    <g>
      <polygon
        fill="currentColor"
        points="40.841 312 144.493 199.12 216.397 271.024 216.397 271.024 292.687 347.313 315.313 324.687 238.244 247.617 327.738 151.73 470.836 312 496 312 496 292.136 328.262 104.27 215.603 224.976 143.507 152.88 16 291.741 16 312 40.841 312"
        class="ci-primary"
      ></polygon>
      <rect
        width="480"
        height="32"
        x="16"
        y="392"
        fill="currentColor"
        class="ci-primary"
      ></rect>
    </g>
  </svg>
</template>
